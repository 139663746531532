.bannerheadingwrapper {
  gap: var(--theme-spacing-12);
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
  text-align: center;
  @media (--theme-mediaQuery-desktop) {
    text-align: left;
  }
}

.bannerheadingwrapper p {
  font-weight: 600;
  font-size: var(--theme-font-size-titleMobile);
  margin: 0;
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-titleDesktop);
  }
}

.bannercontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--theme-spacing-16);
  gap: var(--theme-spacing-8);
  background: var(--theme-colors-alert-attentionBg);
  svg {
    color: var(--theme-color-secondary);
  }
  @media (--theme-mediaQuery-desktop) {
    flex-direction: row;
    align-items: center;
    gap: var(--theme-spacing-12);
  }
}

.bannertext {
  font-size: var(--theme-font-size-captionMobile);
  text-align: center;
  margin: 0 !important;
  @media (--theme-mediaQuery-desktop) {
    text-align: left;
    max-width: 50%;
    font-size: var(--theme-font-size-captionDesktop);
  }
}

.calltoaction span {
  font-size: var(--theme-font-size-titleMobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-titleBody);
  }
}

.notification {
  margin: auto;
  place-content: center;
  border-radius: 0;
  background: var(--theme-colors-alert-attentionBg);
  svg {
    color: var(--theme-color-secondary);
  }
}
