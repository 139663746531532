.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: var(--theme-spacing-32) 0;
  gap: var(--theme-spacing-16);
  background-color: var(--theme-color-primary);
  color: var(--theme-color-white);
  font-weight: 600;
  font-size: var(--theme-font-size-h1SMobile);
  /* --theme-mediaQuery-desktop */
  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-h1LMobile);
  }
  a {
    display: flex;
    align-items: center;
  }
}

.icons {
  display: flex;
  flex-direction: row;
  gap: var(--theme-spacing-16);
}
