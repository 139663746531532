.slideText {
  padding-top: var(--theme-spacing-16);
  text-align: center;
  p {
    font-size: var(--theme-font-size-bodyMobile);
  }
  h2 {
    font-size: var(--theme-font-size-h3Mobile);
  }
  @media (--theme-mediaQuery-desktop) {
    p {
      font-size: var(--theme-font-size-bodyDesktop);
    }
    h2 {
      font-size: var(--theme-font-size-h3Desktop);
    }
  }
}

.slideContainer {
  display: grid;
  gap: var(--theme-spacing-16);
}

.slideImage {
  margin: auto;
  overflow: hidden;
  border-radius: var(--theme-borderRadius);
  @media (--theme-mediaQuery-desktop) {
    width: 65%;
  }
}

.title {
  text-align: center;
  padding-bottom: var(--theme-spacing-48);
  font-size: var(--theme-font-size-h1SDesktop);
}

.lightText {
  color: var(--theme-color-white);
}
