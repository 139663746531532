.container {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-color-primary);
  color: var(--theme-color-white);
  font-weight: 600;
  width: 100%;
  padding: var(--theme-spacing-24);
  font-size: var(--theme-spacing-14);
  align-items: center;
  justify-items: center;
  text-align: center;
  border-radius: var(--theme-borderRadius);
  gap: var(--theme-spacing-16);
  margin: auto;
  @media (--theme-mediaQuery-desktop) {
    padding: var(--theme-spacing-24) var(--theme-spacing-64);
    flex-direction: row;
    text-align: left;
    gap: var(--theme-spacing-48);
  }
}

.shopNowButton {
  display: flex;
  width: 100%;
  @media (--theme-mediaQuery-desktop) {
    width: auto;
  }
}

.shopNowText {
  font-size: var(--theme-font-size-bodyMobile);
}
