.reverse {
  display: flex;
  flex-direction: row-reverse;
  width: fit-content;
  place-self: center;
  svg {
    margin-left: var(--theme-spacing-16);
    margin-right: 0;
  }
}

.font {
  font-size: var(--theme-font-size-h3Mobile) !important;
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-h3Desktop);
  }
}
