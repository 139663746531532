.container {
  h1 {
    font-size: var(--theme-font-size-h1SMobile);
  }
  @media (--theme-mediaQuery-desktop) {
    h1 {
      font-size: var(--theme-font-size-h1SDesktop);
    }
  }
  display: grid;
  justify-items: center;
  align-items: center;
  grid-auto-flow: column;
  width: fit-content;
  gap: var(--theme-spacing-16);
  padding: var(--theme-spacing-16) var(--theme-spacing-0);
  margin: auto;
  img {
    width: var(--theme-spacing-32);
  }
}
