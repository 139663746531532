.section {
  background: linear-gradient(180deg, #ececec 0%, #fafafa 47.92%);
}

.otherLocations {
  text-align: center;
  h2 {
    font-size: var(--theme-font-size-titleMobile);
  }
  @media (--theme-mediaQuery-desktop) {
    h2 {
      font-size: var(--theme-font-size-h2Desktop);
    }
  }
}

.mnsSection {
  background-repeat: repeat;
  background-size: 20px;
}
