.gridcontainer {
  display: grid;
  gap: var(--theme-spacing-32);
  grid-template-columns: 1fr;
  @media (--theme-mediaQuery-desktop) {
    grid-template-columns: 1fr 1fr;
  }
}

.textstyle {
  text-align: center;
  p {
    font-size: var(--theme-font-size-bodyMobile);
  }
  h1 {
    text-decoration: underline;
    text-decoration-color: var(--theme-color-primary);
    text-decoration-thickness: var(--theme-spacing-2);
    text-underline-offset: var(--theme-spacing-8);
  }
  h2 {
    margin: 0;
    font-size: var(--theme-font-size-h4Mobile);
  }
  a {
    margin-top: var(--theme-spacing-4);
  }
  @media (--theme-mediaQuery-desktop) {
    text-align: left;
    p {
      font-size: var(--theme-font-size-bodyDesktop);
    }
    h2 {
      margin: 0;
      font-size: var(--theme-font-size-h4Desktop);
    }
  }
}

.storeinfo {
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing-12);
}

.divider {
  border: var(--theme-color-lightGray1) 1px solid;
}

.link {
  font-size: var(--theme-font-size-h4Mobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-h4Desktop);
  }
}

.storeName {
  font-size: var(--theme-font-size-h1SMobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-h1SDesktop);
  }
}
