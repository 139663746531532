.background {
  background-repeat: repeat;
  padding: var(--theme-spacing-32) 0;
  background: url('https://assets.nintendo.com/image/upload/v1737680146/retail-locations/constants/GridBg.png')
    lightgray 0% 0% / 50px 50px repeat;
}

.farm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--theme-spacing-16) var(--theme-spacing-32);
  padding: var(--theme-spacing-0) var(--theme-spacing-16);
  span {
    font-size: var(--theme-font-size-h4Mobile);
  }
  /* --theme-mediaQuery-tablet */
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    span {
      font-size: var(--theme-font-size-h3Desktop);
    }
  }
}

.hide {
  display: none;
}
