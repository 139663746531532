.container {
  border-radius: var(--theme-borderRadius);
  overflow: hidden;
  border: var(--theme-color-primary) solid var(--theme-spacing-2);
  height: 100%;
  width: 100%;
  display: none;
  @media (--theme-mediaQuery-desktop) {
    display: flex;
  }
}
