@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.images {
  padding: var(--theme-spacing-32) 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.images:hover .imagesSlide {
  animation-play-state: paused;
}

.imagesSlide {
  animation: 60s scroll infinite linear;
  display: inline-flex;
  img {
    height: 340px;
    width: auto;
    margin: 0 var(--theme-spacing-16);
    border-radius: var(--theme-borderRadius);
  }
}
