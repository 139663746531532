.container {
  margin: auto;
  p {
    padding-left: var(--theme-spacing-16);
    padding-right: var(--theme-spacing-24);
  }
}

.heading {
  text-align: center;
  font-size: var(--theme-font-size-h1SMobile);
  padding-bottom: var(--theme-spacing-32);
  @media (--theme-mediaQuery-desktop) {
    padding-bottom: var(--theme-spacing-48);
    font-size: var(--theme-font-size-h1SDesktop);
  }
}

.centerAlign {
  text-align: center;
}

.leftAlign {
  text-align: left;
}

.button {
  width: 100%;
  place-self: center;
  font-size: var(--theme-font-size-titleMobile);
  @media (--theme-mediaQuery-desktop) {
    font-size: var(--theme-font-size-h3Desktop);
  }
}

.accordionText {
  h1 {
    font-size: var(--theme-font-size-titleMobile);
  }
  p {
    font-size: var(--theme-font-size-bodyMobile);
  }
  @media (--theme-mediaQuery-desktop) {
    h1 {
      font-size: var(--theme-font-size-titleDesktop);
    }
    p {
      font-size: var(--theme-font-size-bodyDesktop);
    }
  }
}
