.oneEvent {
  display: flex;
  flex-direction: column;
  img {
    border-radius: var(--theme-borderRadius);
    max-width: 100%;
  }
  @media (--theme-mediaQuery-desktop) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--theme-spacing-32);
    align-items: center;
  }
}

.image {
  display: flex;
  justify-content: flex-end;
}

.text {
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing-12);
  padding: var(--theme-spacing-32) var(--theme-spacing-24);
  p {
    text-align: left;
  }
}

.container {
  display: grid;
  gap: var(--theme-spacing-16);
}
